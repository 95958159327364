import React from 'react';
import './AuthError.scss';
import Nope from './Nope.svg';

const AuthErrorRender = (error) => (
    <div>{error}</div>
);

const AuthError = (props) => {
    if (_.isEmpty(props.errors)) return null;
    return (
        <div className="auth-errors">
            <img src={Nope} alt="icon-nope" />
            <div>
                {_.forEach(props.errors, (error) => <AuthErrorRender error={error} />)}
            </div>
        </div>
    );
};

export default AuthError;
