import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';

import Login from '../../railfleet/lib/compounds/pages/authentication/login/Login';
import ForgotPassword from '../../railfleet/lib/compounds/pages/authentication/forgotPassword/ForgotPassword';
import CreateAccount from '../../railfleet/lib/compounds/pages/authentication/createAccount/CreateAccount';
import ValidationAction from '../../railfleet/lib/compounds/pages/authentication/validationAction/ValidationAction';
import AccountActivation from '../../railfleet/lib/compounds/pages/authentication/accountActivation/AccountActivation';
import ResetPassword from '../../railfleet/lib/compounds/pages/authentication/resetPassword/ResetPassword';

const render = (
    component,
    urls,
    errors,
    fields,
) => {
    let Component;
    switch (component) {
    case 'activate_complete':
        Component = <AccountActivation urls={urls} errors={errors} fields={fields} />;
        break;
    case 'login':
        Component = <Login urls={urls} errors={errors} />;
        break;
    case 'logout':
        Component = <Login urls={urls} errors={errors} logout />;
        break;
    case 'registration':
        Component = <CreateAccount urls={urls} errors={errors} />;
        break;
    case 'registration_complete':
        Component = (
            <ValidationAction
                errors={errors}
                title={i18n.t('Registration link was sent to your email!')}
                text={i18n.t('Please go to your email and click the link to continue.')}
            />
        );
        break;
    case 'password_reset':
        Component = <ForgotPassword urls={urls} errors={errors} />;
        break;
    case 'password_reset_done':
        Component = (
            <ValidationAction
                errors={errors}
                title={i18n.t('Thanks!')}
                text={i18n.t('The instructions have been successfully sent by email.')}
            />
        );
        break;
    case 'password_reset_confirm':
        Component = <ResetPassword urls={urls} errors={errors} fields={fields} />;
        break;
    case 'password_reset_complete':
        Component = <Login urls={urls} errors={errors} reset />;
        break;
    case 'account_activated':
        Component = (
            <ValidationAction
                errors={errors}
                title={i18n.t('Activation failed!')}
                text={i18n.t('We couldn\'t activate your account. Please contact the support')}
            />
        );
        break;
    default:
    }
    ReactDOM.render(
        Component,
        document.getElementById('react_entry_point'),
    );
};

export {
    render,
};

export default render;
