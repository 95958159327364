import React from 'react';
import i18n from 'i18next';
import ChoosePassword from '../choosePassword/ChoosePassword';

const AccountActivation = (props) => (
    <ChoosePassword
        actionText={i18n.t('Activate your account')}
        subtitle={i18n.t('Activate your new account by choosing a password')}
        action="/accounts/activate/complete/"
        errors={props.errors}
        fields={props.fields}
    />
);

export default AccountActivation;
