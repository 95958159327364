import React from 'react';
import i18n from 'i18next';
import BaseForm from '../baseForm/BaseForm';
import OkImg from './Ok.svg';
import FailImg from './Fail.svg';
import './ValidationAction.scss';
import TextAction from '../../../../components/buttons/textAction/TextAction';

const ValidationAction = (props) => {
    const onClick = () => {
        window.location.href = '/accounts/login';
    };

    const renderActions = () => (
        <div className="validation-action">
            {
                props.fail
                    ? (
                        <img src={FailImg} alt="ok img" />
                    ) : (
                        <img src={OkImg} alt="fail img" />
                    )
            }
            <div>
                <div>{props.title}</div>
                <div className="validation-action-text">{props.text}</div>
                <TextAction
                    text={i18n.t('Back to login')}
                    onClick={onClick}
                />
            </div>
        </div>
    );

    return (
        <div className="authentication">
            <BaseForm
                errors={props.errors}
                renderActions={renderActions}
            />
        </div>
    );
};

export default ValidationAction;
