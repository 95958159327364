import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import zxcvbn from 'zxcvbn';
import { ContextDevice, withMultiDeviceLayout } from '../../../../components/app/MultiDeviceLayout';
import FormManager from '../../../../components/forms/RailForms/FormManager';
import FormTextInput from '../../../../components/forms/RailForms/formTextInput/FormTextInput';
import BaseForm from '../baseForm/BaseForm';
import TextAction from '../../../../components/buttons/textAction/TextAction';
import Button from '../../../../components/buttons/button/Button';
import Tooltip from '../../../../components/frames/tooltip/Tooltip';
import './choosePassword.scss';
import Bulb from './Bulb.svg';

const ChoosePassword = (props) => {
    const { device } = useContext(ContextDevice);
    const [_password, setPassword] = useState('');
    const [_password2, setPassword2] = useState('');
    const [_identical, setIdentical] = useState(false);
    const [_visible, setVisible] = useState(true);
    const [_strength, setStrength] = useState(null);
    const [_score, setScore] = useState(0);
    const [_showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (_strength) {
            setScore(_strength.score || 0);
        }
    }, [_strength]);

    useEffect(() => {
        if (_password !== undefined) {
            const strength = zxcvbn(_password);
            setStrength(strength);
        }
    }, [_password]);

    useEffect(() => {
        if (_score >= 3) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [_score]);

    useEffect(() => {
        if (_password && _password2) {
            setIdentical(_password === _password2);
        }
    }, [_password, _password2]);

    const onCancel = () => {
        window.location.href = '/accounts/login';
    };

    const renderActions = () => (
        <div className="authentication-body-actions">
            <TextAction
                label={i18n.t('Already have an account?')}
                onClick={onCancel}
            />
            <Button
                label={props.actionText || i18n.t('Activate your account')}
                primary
                submitButton
                disabled={!_password || !_password2 || !_identical || _score < 3}
            />
        </div>
    );

    const StrongPassword = () => {
        const strength_text = {
            0: i18n.t('Your password is too easily guessable. You can do better.'),
            1: i18n.t('Your password is too easily guessable. You can do better.'),
            2: i18n.t('Still a bit weak password, it could be stronger. Keep going on.'),
            3: i18n.t('Your password is great. Nice work!'),
            4: i18n.t('Excellent password! It would take decade to hack'),
        };
        return (
            <div className="password-meter">
                <div className={`bar score_${_score}`}>
                    <div className="pframe weak" />
                    <div className="pframe medium" />
                    <div className="pframe strong" />
                    <div className="pframe very-strong" />
                </div>
                <div className="strength-text">{strength_text[_score] || ''}</div>
            </div>
        );
    };

    const renderForm = () => (
        <div className="authentication-body-fields">
            <div className={`form-main ${device} authentication-form`}>
                <FormManager
                    legacy
                    action={props.action === undefined ? '/accounts/activate/complete/' : props.action}
                >
                    <FormTextInput
                        id="email"
                        defaultValue={props.fields && props.fields.email}
                        placeholder={i18n.t('email')}
                        required
                        disabled
                    />
                    <div className="tooltip-positioner">
                        <FormTextInput
                            className={`${!!_password && _score < 3 ? ' validation-error' : (_password && _score >= 3 ? ' validation-ok' : '')}`}
                            id="new_password1"
                            placeholder={i18n.t('Password')}
                            onChange={setPassword}
                            required
                            password
                            showPassword={_showPassword}
                            onClickShowPassword={() => setShowPassword(!_showPassword)}
                        />
                        <Tooltip
                            title={i18n.t('A few suggestions to make your password stronger:')}
                            visible={!!_password && _score < 3}
                            noIcon
                            placement="right"
                            noEvent
                            icon={Bulb}
                        >
                            <div className="password-strength">
                                <ul>
                                    <li>{i18n.t('Uppercase & lowercase ')}</li>
                                    <li>{i18n.t('Numbers')}</li>
                                    <li>{i18n.t('Special characters')}</li>
                                    <li>{i18n.t('At least 8 characters')}</li>
                                    <li>{i18n.t('Long passwords are stronger')}</li>
                                </ul>
                            </div>
                        </Tooltip>
                    </div>
                    <StrongPassword />
                    <FormTextInput
                        className={`${!!_password2 && !_identical ? ' validation-error' : (!!_password2 && _identical ? ' validation-ok' : '')}`}
                        id="new_password2"
                        placeholder={i18n.t('Confirm your password')}
                        onChange={setPassword2}
                        required
                        password
                        showPassword={_showPassword}
                        onClickShowPassword={() => setShowPassword(!_showPassword)}
                        valueError={!!_password2 && !_identical ? {
                            message: i18n.t('Confirmation password does not match'),
                        } : null}
                    />
                    {
                        renderActions()
                    }
                </FormManager>
            </div>
        </div>
    );

    return (
        <div className="authentication">
            <BaseForm
                title={props.title || i18n.t('Choose a password')}
                subtitle={props.subtitle === undefined ? i18n.t('Activate your new account by choosing a password') : props.subtitle}
                renderForm={renderForm}
                errors={props.errors}
            />
        </div>
    );
};

export default withMultiDeviceLayout(ChoosePassword);
