import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { ContextDevice, withMultiDeviceLayout } from '../../../../components/app/MultiDeviceLayout';
import FormManager from '../../../../components/forms/RailForms/FormManager';
import FormTextInput from '../../../../components/forms/RailForms/formTextInput/FormTextInput';
import BaseForm from '../baseForm/BaseForm';
import TextAction from '../../../../components/buttons/textAction/TextAction';
import Button from '../../../../components/buttons/button/Button';

const emailValidator = /^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
const CreateAccount = (props) => {
    const { device } = useContext(ContextDevice);
    const [_email, setEmail] = useState('');
    const [_correctFormatMail, setCorrectFormatMail] = useState(false);

    const onCancel = () => {
        window.location.href = '/accounts/login';
    };

    useEffect(() => {
        setCorrectFormatMail(emailValidator.test(_email));
    }, [_email]);

    const renderActions = () => (
        <div className="authentication-body-actions">
            <TextAction
                label={i18n.t('Already have an account?')}
                onClick={onCancel}
            />
            <Button
                label={i18n.t('Create your account')}
                primary
                submitButton
                disabled={!_correctFormatMail}
            />
        </div>
    );

    const renderForm = () => (
        <div className="authentication-body-fields">
            <div className={`form-main ${device} authentication-form`}>
                <FormManager
                    legacy
                >
                    <FormTextInput
                        className={`${!_.isEmpty(props.errors) ? ' validation-error' : ''}`}
                        id="email"
                        placeholder={i18n.t('E-mail')}
                        onChange={setEmail}
                        required
                    />
                    <input name="password1" type="hidden" value="Xy123456" />
                    <input name="password2" type="hidden" value="Xy123456" />
                    { renderActions() }
                </FormManager>
            </div>
        </div>
    );

    return (
        <div className="authentication">
            <BaseForm
                title={i18n.t('Create an account')}
                subtitle={i18n.t('We will send you activation instructions by email')}
                renderForm={renderForm}
                errors={props.errors}
            />
        </div>
    );
};

export default withMultiDeviceLayout(CreateAccount);
