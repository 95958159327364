import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { ContextDevice, withMultiDeviceLayout } from '../../../../components/app/MultiDeviceLayout';
import FormManager from '../../../../components/forms/RailForms/FormManager';
import FormTextInput from '../../../../components/forms/RailForms/formTextInput/FormTextInput';
import BaseForm from '../baseForm/BaseForm';
import TextAction from '../../../../components/buttons/textAction/TextAction';
import Button from '../../../../components/buttons/button/Button';

const ForgotPassword = (props) => {
    const { device } = useContext(ContextDevice);
    const [_email, setEmail] = useState('');

    const onCancel = () => {
        window.location.href = '/accounts/login';
    };

    const renderActions = () => (
        <div className="authentication-body-actions">
            <TextAction
                label={i18n.t('Cancel')}
                onClick={onCancel}
            />
            <Button
                label={i18n.t('Reset password')}
                primary
                submitButton
                disabled={!_email}
            />
        </div>
    );

    const renderForm = () => (
        <div className="authentication-body-fields">
            <div className={`form-main ${device} authentication-form`}>
                <FormManager
                    legacy
                    textSubmitButton={i18n.t('Reset password')}
                    action="/accounts/password/reset/"
                >
                    <FormTextInput
                        id="email"
                        placeholder={i18n.t('E-mail')}
                        onChange={setEmail}
                        required
                    />
                    { renderActions() }
                </FormManager>
            </div>
        </div>
    );

    return (
        <div className="authentication">
            <BaseForm
                title={i18n.t('Forgot your password?')}
                subtitle={i18n.t('We will send you instructions by email on how to reset it')}
                renderForm={renderForm}
                errors={props.errors}
            />
        </div>
    );
};

export default withMultiDeviceLayout(ForgotPassword);
