import React from 'react';
import i18n from 'i18next';
import ChoosePassword from '../choosePassword/ChoosePassword';

const ResetPassword = (props) => (
    <ChoosePassword
        actionText={i18n.t('Change password')}
        title={i18n.t('Choose a new password')}
        subtitle={null}
        action=""
        errors={props.errors}
        fields={props.fields}
    />
);

export default ResetPassword;
