import React, { useContext } from 'react';
import i18n from 'i18next';
import { ContextDevice, withMultiDeviceLayout } from '../../../../components/app/MultiDeviceLayout';
import './BaseForm.scss';
import RailnovaLogo from './img/railnova_logo.png';
import AuthError from '../authError/AuthError';
import LanguageSelector from './LanguageSelector';

const BaseForm = (props) => {
    const { device } = useContext(ContextDevice);
    return (
        <div className={`authentication-wrapper ${device}`}>
            <div className="authentication-head">
                <div className="head-railnova">
                    <img src={RailnovaLogo} alt="railnova logo" />
                </div>
                {
                    props.company
                    && (
                        <div className="head-client">
                            {props.company.toUpperCase()}
                        </div>
                    )
                }
            </div>
            <div className="authentication-body">
                {
                    (props.title || props.subtitle)
                    && (
                        <div className="authentication-body-title">
                            <div>{props.title}</div>
                            <div>{props.subtitle}</div>
                        </div>
                    )
                }
                <AuthError errors={props.errors} />
                { props.renderForm && props.renderForm() }
                { props.renderActions && props.renderActions() }
                { props.renderAdditional && props.renderAdditional() }
            </div>
            <div className={`authentication-footer${props.sso ? ' sso-auth' : ''}`}>
                <div>
                    {i18n.t('Need help ? ')}
                    <a href="mailto:support@railnova.eu">{i18n.t('Contact support')}</a>
                </div>
                <LanguageSelector />
            </div>
        </div>
    );
};

export default withMultiDeviceLayout(BaseForm);
