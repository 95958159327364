import React, {
    Component, useContext, useEffect, useState,
} from 'react';
import i18n from 'i18next';
import google_image from './img/google_logo.svg';
import azure_image from './img/azure.png';
import Chevron from './img/chevron-down.svg';
import './Login.scss';
import { ContextDevice, withMultiDeviceLayout } from '../../../../components/app/MultiDeviceLayout';
import FormManager from '../../../../components/forms/RailForms/FormManager';
import FormTextInput from '../../../../components/forms/RailForms/formTextInput/FormTextInput';
import Button from '../../../../components/buttons/button/Button';
import TextAction from '../../../../components/buttons/textAction/TextAction';
import BaseForm from '../baseForm/BaseForm';
import Toast from '../../../../components/frames/toast/Toast';

const emailValidator = /^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
const Login = (props) => {
    const { device } = useContext(ContextDevice);
    const [_email, setEmail] = useState('');
    const [_password, setPassword] = useState(null);
    const [_company, setCompany] = useState(null);
    const [_askEmail, setAskEmail] = useState(false);
    const [_authMethods, setAuthMethods] = useState(null);
    const [_companyContext, setCompanyContext] = useState(false);

    const [_displayPassword, setDisplayPassword] = useState(false);
    const [_displayForm, setDisplayForm] = useState(false);
    const [_submitText, setSubmitText] = useState(i18n.t('Login'));
    const [_correctFormatMail, setCorrectFormatMail] = useState(false);
    const [_logout, setLogout] = useState(false);
    const [_reset, setReset] = useState(false);
    const [_secret, setSecret] = useState(false);

    const active = _company && _authMethods;

    const handleEventClick = (event) => {
        if (event.ctrlKey && event.target.classList.contains('head-railnova')) {
            setSecret(true);
        } else {
            setSecret(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleEventClick);
        return () => {
            document.removeEventListener('mousedown', handleEventClick);
        };
    }, []);

    useEffect(() => {
        // when we load the page, no mail but with an authmethod with a company, we never disabled the field username
        // which only occurs when we come with a custom company url
        if (_companyContext) return;
        if (!_email && _company) {
            setCompanyContext(true);
        }
    }, [_authMethods]);

    useEffect(() => {
        if (_secret) {
            setEmail('a@railnova.eu');
            $.getJSON('/api/v2/company/auth_methods.json?email=a@railnova.eu', (d) => {
                setCompany(d.company);
                setAskEmail(d.ask_email);
                setAuthMethods(d.auth_methods);
            });
        }
    }, [_secret]);

    const auth_method_query = () => {
        $.getJSON('/api/v2/company/auth_methods.json', (d) => {
            setEmail(d.email);
            setCompany(d.company);
            setAskEmail(d.ask_email);
            setAuthMethods(d.auth_methods);
        });
    };

    useEffect(() => {
        if (props.development) {
            setEmail(props.auth_methods.email);
            setCompany(props.auth_methods.company);
            setAskEmail(props.auth_methods.ask_email);
            setAuthMethods(props.auth_methods.auth_methods);
        } else {
            auth_method_query();
        }
    }, []);

    useEffect(() => {
        setCorrectFormatMail(emailValidator.test(_email));
    }, [_email]);

    useEffect(() => {
        if (props.logout && !_logout) {
            window.history.replaceState(null, '', '/accounts/login');
        }
    }, [props.logout]);

    useEffect(() => {
        if (props.reset && !_reset) {
            window.history.replaceState(null, '', '/accounts/login');
        }
    }, [props.reset]);

    useEffect(() => {
        let displayPassword = false;
        let displayForm = true;
        if (!_company && !_askEmail) {
            // we don't know the company though the user give his email
            // display the password field and let django handle the auth
            displayPassword = true;
        } else if (_company && _authMethods) {
            if (_authMethods.password) {
                displayPassword = true;
            } else {
                displayForm = false;
            }
        }

        let submitText = (_askEmail && !_companyContext) ? i18n.t('Next') : i18n.t('Login');
        if (!displayForm) {
            submitText = null;
        }
        setDisplayForm(displayForm);
        setDisplayPassword(displayPassword);
        setSubmitText(submitText);
    }, [_company, _askEmail, _authMethods, _companyContext]);

    const onClickNext = (evt) => {
        if (!_askEmail || _companyContext) {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        $.getJSON(`/api/v2/company/auth_methods.json?email=${_email}`, (d) => {
            if (
                d.company !== 'railnova'
                && d.auth_methods
                && d.auth_methods.azure
                && window.location.host !== `${d.company}.railnova.eu`
                // issue #12422: only redirect to the company's domain in production
                && !window.location.host.startsWith('localhost')
                && window.location.host !== 'dev.railnova.eu'
                && window.location.host !== 'test.railnova.eu'
            ) {
                // issue #11730: to support Azure the domain of the login URL needs to be changed to the
                //               particular hostname of the desired user's company.
                const reload_url = window.location.href.replace(/\/\/.*?\//, `//${d.company}.railnova.eu/`);
                window.location.href = reload_url;
            } else {
                setCompany(d.company);
                setAskEmail(d.ask_email);
                setAuthMethods(d.auth_methods);
            }
        });
    };

    const onCancel = () => {
        auth_method_query();
    };
    const renderActions = () => {
        if (!_displayForm) return null;
        return (
            <div className="authentication-body-actions">
                {
                    _authMethods
                        ? (
                            <TextAction
                                label={i18n.t('Forgot your password?')}
                                onClick={() => {
                                    window.location.href = props.urls.password_reset_url;
                                }}
                            />
                        ) : (
                            <div>
                                <TextAction
                                    label={i18n.t('Create an account')}
                                    onClick={() => {
                                        window.location.href = props.urls.registration_url;
                                    }}
                                />
                                <TextAction
                                    label={i18n.t('Forgot your password?')}
                                    onClick={() => {
                                        window.location.href = props.urls.password_reset_url;
                                    }}
                                />
                            </div>
                        )
                }
                <Button
                    label={_submitText}
                    primary
                    submitButton
                    disabled={!_email || (_displayPassword && !_password) || !_correctFormatMail}
                    onClick={onClickNext}
                />
            </div>
        );
    };
    const renderForm = () => (
        <div className="authentication-body-fields">
            <div className={`form-main ${device} authentication-form`}>
                <FormManager
                    legacy
                    action="/accounts/login/"
                >
                    {
                        (!_authMethods || _authMethods?.password)
                        && (
                            <FormTextInput
                                className={`${!_.isEmpty(props.errors) ? ' validation-error' : ''}`}
                                id="username"
                                defaultValue={_email}
                                placeholder={i18n.t('E-mail')}
                                onChange={setEmail}
                                required
                                disabled={!_companyContext && (!!_authMethods || _displayPassword)}
                                onEdit={onCancel}
                                valueError={!_correctFormatMail && !_.isEmpty(_email) ? {
                                    message: i18n.t('Please provide a valid email'),
                                } : null}
                            />
                        )
                    }
                    {
                        _displayForm && _displayPassword
                        && (
                            <FormTextInput
                                id="password"
                                placeholder={i18n.t('Password')}
                                onChange={setPassword}
                                required
                                password
                            />
                        )
                    }
                    {
                        renderActions()
                    }
                </FormManager>
            </div>
        </div>
    );

    const renderAdditional = () => {
        if (!active || !_authMethods || (!_authMethods.google && !_authMethods.azure)) return null;
        return (
            <div className="authentication-body-sso">
                {
                    active && _authMethods && _authMethods.google
                    && (
                        <SSOButton
                            onClick={() => {
                                window.location.href = `/auth/login/rn-google-oauth2?company=${_company}`;
                            }}
                            img={google_image}
                            alt="google_image"
                            text={i18n.t('Login via Google')}
                            company={_company}
                            primary={!_authMethods.azure}
                        />
                    )
                }
                {
                    active && _authMethods && _authMethods.azure
                    && (
                        <SSOButton
                            onClick={() => {
                                window.location.href = `/auth/login/rn-azuread-oauth2?company=${_company}`;
                            }}
                            img={azure_image}
                            alt="azure_image"
                            text={i18n.t('Login via Azure')}
                            company={_company}
                            primary={!_authMethods.google}
                        />
                    )
                }
            </div>
        );
    };

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    return (
        <div className="authentication">
            {
                props.logout && !_logout
                && (
                    <Toast
                        text={i18n.t('You have successfully logged out')}
                        onClick={(evt) => {
                            if (evt) {
                                evt.preventDefault();
                                evt.stopPropagation();
                            }
                            setLogout(true);
                        }}
                    />
                )
            }
            {
                props.reset && !_reset
                && (
                    <Toast
                        text={i18n.t('Password reset completed')}
                        onClick={(evt) => {
                            if (evt) {
                                evt.preventDefault();
                                evt.stopPropagation();
                            }
                            setReset(true);
                        }}
                    />
                )
            }
            <BaseForm
                title={(_authMethods && _email ? `${i18n.t('Hi')} ${capitalizeFirst(_email.split('.')[0])}` : i18n.t('Log in'))}
                subtitle={i18n.t('Continue to Railnova')}
                renderForm={renderForm}
                renderAdditional={renderAdditional}
                errors={props.errors}
                company={_company !== 'railnova' && _company}
                sso={_authMethods && (_authMethods.azure || _authMethods.azure.google)}
            />
        </div>
    );
};

const SSOButton = (props) => (
    <div
        className={`sso-button${props.primary ? ' primary' : ''}`}
        onClick={props.onClick}
    >
        <div>
            <div className="sso-icon">
                <img src={props.img} alt={props.alt} />
            </div>
            <div>{props.text}</div>
        </div>
        <img src={Chevron} alt="arrow" />
    </div>
);

export default withMultiDeviceLayout(Login);
